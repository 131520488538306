
export const StartCallButton = ({ onClick }: {
    onClick: () => void
}) => {
    return (
        <div style={{
            height: 44,
            width: 138,
            background: '#0094FF',
            borderRadius: 22,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            gap: 8,
            fontSize: 14,
            cursor: 'pointer'

        }}
            onClick={onClick}
        >
            <img src='/images/call-button.svg' />
            <div>
                Start a Call
            </div>
        </div>
    )
}