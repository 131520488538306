import { Route, Routes, useParams } from "react-router-dom";
import { useEffect } from "react";
import { fetchAvatarConfigs } from "../../app/api";
import { useDispatch } from "react-redux";
import { AvatarConfig } from "../../app/types";
import { capitalizeFirstLetter, customAlert } from "../../app/utils";
import HeaderComponent, { headerHeight } from "../Chat/HeaderComponent";
import { setCurrentAvatarConfigAndGetSuggestedTopics } from "../../app/redux/actions";
import { setAvatarConfigs } from "../../app/redux/avatarsSlice";
import WelcomeDiscoverComponent from "./WelcomeDiscoverComponent";

const IntroScreenComponent = ({ onStartSession }: {
    onStartSession: () => void
}) => {

    return <Routes>
        <Route
            path='/:avatarName?'
            element={
                <>
                    <HeaderComponent intro />

                    <div style={{ height: `calc(100% - ${headerHeight}px)` }}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                        }}
                        >
                            
                            <WelcomeDiscoverComponent onStartSession={onStartSession}/>

                        </div>
                    </div>

                    <AvatarLoader />
                </>
            } />
    </Routes>
}

const AvatarLoader = () => {
    const dispatch = useDispatch()
    const params = useParams()
    const avatarName = params.avatarName

    const capitalizedAvatarNameOrFilterForAll = avatarName ? capitalizeFirstLetter(avatarName) : ''

    useEffect(() => {
        fetchAvatarConfigs(capitalizedAvatarNameOrFilterForAll)
            .then((avatarConfigs: AvatarConfig[]) => {
                if (avatarConfigs?.length > 0) {
                    dispatch(setAvatarConfigs(avatarConfigs))
                    if (capitalizedAvatarNameOrFilterForAll != '') {
                        dispatch(setCurrentAvatarConfigAndGetSuggestedTopics(avatarConfigs[0]))
                    }
                } else {
                    console.error('no avatars')
                }
            })
            .catch((err: any) => {
                alert(err)
            })
    }, [avatarName])

    return <></>
}

export default IntroScreenComponent

