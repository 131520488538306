import { useEffect, useMemo, useRef, useState } from "react"
import { fetchAvatarConfigs } from "../../app/api"
import { useSelector } from "react-redux"
import { selectAvatarConfigs } from "../../app/redux/avatarsSlice"
import { AvatarConfig } from "../../app/types"
import './discover.css'
import { useCookies } from "react-cookie"
import { lastUserAvatarKey } from "../../app/const-keys"

const discoverTileWidth = 350
const discoverTileHeight = 166
export const defaultMarginX = 100

export default ({ onSelect, onSideMarginCalculated, onLoaded, addLastUsed, marginX }: {
    onSelect: (avatar: AvatarConfig) => void,
    onSideMarginCalculated?: (margin: number) => void,
    onLoaded?: () => void,
    addLastUsed?: boolean,
    marginX?: number
}) => {

    const avatarConfigs = useSelector(selectAvatarConfigs)

    const [cookies, setCookie] = useCookies<string>([lastUserAvatarKey])

    const selectAndSave = (avatar: AvatarConfig) => {
        onSelect(avatar)
        setCookie(lastUserAvatarKey, avatar.name)
    }

    const discoverMarginX = marginX ?? defaultMarginX

    const lastUsedAvatarName = cookies[lastUserAvatarKey]
    const lastUsedAvatar = useMemo(() => {
        if (lastUsedAvatarName && (avatarConfigs ?? []).length > 0) {
            return avatarConfigs?.find((avatarConfig: AvatarConfig) => avatarConfig.name == lastUsedAvatarName)
        } else {
            return null
        }
    }, [lastUsedAvatarName, avatarConfigs])

    useEffect(() => {
        if (avatarConfigs) {
            onLoaded?.()
        }
    }, [avatarConfigs])

    const divRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleResize = (entries: any) => {
            requestAnimationFrame(() => {
                const entry = entries[0]
                const { width } = entry.contentRect

                const gap = 15
                const n = Math.floor((width + gap) / (discoverTileWidth + gap))
                const xMargin = (width - n * discoverTileWidth - (n - 1) * gap) / 2
                if (divRef.current) {
                    divRef.current.style.margin = `0px ${xMargin}px`
                    onSideMarginCalculated?.(xMargin + discoverMarginX)
                }
            })
        }

        const resizeObserver = new ResizeObserver(handleResize)

        if (divRef.current) {
            resizeObserver.observe(divRef.current)
        }

        return () => {
            if (divRef.current) {
                resizeObserver.unobserve(divRef.current)
            }
        };
    }, []);


    return (
        <div style={{
            marginLeft: discoverMarginX,
            marginRight: discoverMarginX,
        }}>
            <div ref={divRef} className='discover__tiles'>
                {avatarConfigs?.map((avatarConfig: AvatarConfig, i) => <TileComponent
                    key={i}
                    avatarConfig={avatarConfig}
                    onSelect={selectAndSave}
                />)}
            </div>

            {lastUsedAvatar && addLastUsed && <div
                style={{ marginLeft: divRef.current?.style.marginLeft }}
                className='mt-[32px] flex flex-col items-start gap-[16px]'>
                <div className='discover__subtitle'>
                    Jump back in
                </div>
                <TileComponent
                    avatarConfig={lastUsedAvatar}
                    onSelect={selectAndSave}
                />
            </div>}
        </div>
    )
}

const TileComponent = ({ avatarConfig, onSelect }: {
    avatarConfig: AvatarConfig,
    onSelect: (avatar: AvatarConfig) => void,
}) => {

    return (
        <div
            style={{
                width: discoverTileWidth,
                height: discoverTileHeight,
            }}
            className='discover__tile flex gap-[9px]'
            onClick={() => onSelect(avatarConfig)}>

            <img
                className='ml-[11px] mt-[23px] w-[120px] h-[120px] rounded-[60px]'
                src={avatarConfig.image_url}
            />

            <div className='flex flex-col text-left gap-[3px] mt-[12px] pr-[8px]'>
                <div className='font-bold text-[18px] flex gap-[7px]'>
                    {avatarConfig.display_name}
                    {avatarConfig.is_verified && <img src='/images/verified.svg' />}
                </div>

                <div className='text-[16px] font-medium'>
                    {avatarConfig.profession}
                </div>

                <div className='text-[14px] opacity-80 discover__tile-truncated-text'>
                    {avatarConfig.bio}
                </div>
            </div>
        </div>
    )
}

