import { authDataKey } from "./const-keys";
import { apiRoot } from "./config";
import { sendMessageToStreamer } from "./webrtc/webRtcPlayer-bridge-messaging";
import Cookies from 'js-cookie';
import { AvatarAnswer, AvatarConfig, SuggestedTopics, UserQuestion } from "./types";
import decode from 'decode-base64';

export const unauthedError = 'Session expired, please log in again to continue.'

const authedFetch = (url: string, options: RequestInit = {}): Promise<Response> => {
    options.headers = options.headers || {}

    options.headers = {
        ...options.headers,
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtFromCookie()}`
    };

    return fetch(url, options)
        .then((response) => {
            if (response.status == 401) {
                logoutLocally()
                throw new Error(unauthedError)
            } else if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response
        })
}

export function sendQuestionAudioOnly(question: UserQuestion, avatarName: string, abortSignal: AbortSignal): Promise<AvatarAnswer> {
    const apiPath = '/chat_audio_only';

    const requestOptions = formRequestOptions(question, avatarName, abortSignal)

    return authedFetch(apiRoot + apiPath, requestOptions)
        .then(async response => {
            const audioBlob = await response.blob()
            const encodedAnswer = response.headers.get('X-Answer')!
            const uint8Array = decode(encodedAnswer)
            const decoder = new TextDecoder('utf-8');
            const text = decoder.decode(uint8Array)

            return {
                text,
                audioBlob
            }
        })
}

export function sendQuestionToExhumanHLS(question: UserQuestion, avatarName: string, abortSignal: AbortSignal): Promise<AvatarAnswer> {
    return sendQuestionWithPath('/chat_exh_hls', question, avatarName, abortSignal)
}

export function sendQuestionToMaxine(question: UserQuestion, avatarName: string, abortSignal: AbortSignal): Promise<AvatarAnswer> {
    return sendQuestionWithPath('/chat_maxine', question, avatarName, abortSignal)
}

export function sendQuestion(question: UserQuestion, avatarName: string, abortSignal: AbortSignal): Promise<AvatarAnswer> {
    return sendQuestionWithPath('/chat', question, avatarName, abortSignal)
}

function formRequestOptions(question: UserQuestion, avatarName: string, abortSignal: AbortSignal) {
    const data = {
        question: question.text,
        config: {
            avatar_name: avatarName
        },
        input_type: question.inputType,
        asr_stats: {
            latencyMs: question.latency ?? null
        }
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        signal: abortSignal
    };

    return requestOptions
}

function sendQuestionWithPath(apiPath: string, question: UserQuestion, avatarName: string, abortSignal: AbortSignal): Promise<AvatarAnswer> {
    const requestOptions = formRequestOptions(question, avatarName, abortSignal)

    return authedFetch(apiRoot + apiPath, requestOptions)
        .then(async response => {
            const js = await response.json()
            return {
                text: js.avatar_response,
                m3u8Url: js.video_url
            }
        })
}

export function sendQuestionToMetahuman(question: UserQuestion, avatarName: string, onFinishedSpeaking: () => void, abortSignal: AbortSignal): Promise<string> {
    const apiPath = '/chat';
    const data = {
        question: question.text,
        config: {
            avatar_name: avatarName
        },
        input_type: question.inputType
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        signal: abortSignal
    };

    sendMessageToStreamer('KeyDown', [51, false]);  // 51 is thinking.

    return authedFetch(apiRoot + apiPath, requestOptions)
        .then(response => response.json())
        .then((data: any) => {
            requestSpeech(data['avatar_response'], onFinishedSpeaking)

            sendMessageToStreamer('KeyDown', [50, false]);  // 50 is talking

            const responseText = data['avatar_response']
            return responseText
        })
        .catch(error => {
            sendMessageToStreamer('KeyDown', [49, false]);  // 49 is idle
            onFinishedSpeaking()
            throw error
        });
}

function requestSpeech(text: string, onFinishedSpeaking: () => void) {
    const apiPath = '/speak';
    const data = {
        text: text,
    };

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
    };

    authedFetch(apiRoot + apiPath, requestOptions)
        .then(response => response.json())
        .then(data => {
            setTimeout(() => {
                sendMessageToStreamer('KeyDown', [49, false]);  // 49 is idle
                onFinishedSpeaking()
            }, data['audio_seconds'] * 1000);
        }).catch(err => {
            alert(`request speech: ${err}`)
            onFinishedSpeaking()
        })
}

export function login(googleCode: string, validatedPromoCode: string | null): Promise<any> {
    const apiPath = '/login';
    const data = {
        googleCode: googleCode,
        promoCode: validatedPromoCode
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };

    return fetch(apiRoot + apiPath, requestOptions)
        .then(async (response) => {
            let json
            try {
                json = await response.json()
            }
            catch {
                json = null
            }

            const responseStatusH = response.status / 100
            let detail = ''
            if (json?.detail) {
                detail = json?.detail
            }

            if (responseStatusH == 5) {
                throw 'Internal server error'
            }
            else if (responseStatusH == 4) {
                if (detail) {
                    throw detail
                } else {
                    throw `${response.status} error`
                }
            }
            return json
        })
}

export function sendSessionIntro() {
    sendMessageToStreamer('KeyDown', [52, false]);  // 52 is intro.
}

export function checkPromoCode(promoCode: string): Promise<void> {
    const apiPath = '/check-promo-code';
    const data = {
        promoCode: promoCode
    };

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    };

    return fetch(apiRoot + apiPath, requestOptions)
        .then(async (response) => {
            if (response.status / 100 == 5) {
                throw 'Internal server error'
            } else if (response.status / 100 == 4) {
                throw 'Invalid promo code. Please check and try again.'
            } else if (response.status / 100 == 2) {
                return
            } else {
                const json = await response.json()
                throw json.message
            }
        })
}

export function fetchAvatarConfigs(name: string): Promise<AvatarConfig[]> {
    const apiPath = '/avatar-config?name=' + encodeURIComponent(name);

    return authedFetch(apiRoot + apiPath, { method: 'GET' })
        .then(response => response.json())
}

export function fetchSuggestedTopics(avatarId: number): Promise<SuggestedTopics> {
    const apiPath = `/suggested-topic?avatar_id=${avatarId}`;

    return authedFetch(apiRoot + apiPath, { method: 'GET' })
        .then(response => response.json())
        .then((suggestedTopics: SuggestedTopics) => {
            if (!suggestedTopics) {
                throw `nil suggested topics (${suggestedTopics})`
            }
            return suggestedTopics
        })
}

export function fetchHistory(avatarId: number): Promise<any> {
    const apiPath = '/chathistory';

    const params: { [key: string]: any } = {
        avatar_id: avatarId,
    };

    const urlWithParams = `${apiRoot + apiPath}?${(new URLSearchParams(params).toString())}`;

    return authedFetch(urlWithParams, { method: 'GET' })
        .then(response => response.json())
        .then((history: any) => {
            return history
        })
}

function jwtFromCookie() {
    return JSON.parse(Cookies.get(authDataKey) ?? '{}').jwt_token
}

export function logoutLocally() {
    Cookies.remove(authDataKey)
}