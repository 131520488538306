
export default ({ children, width }: {
    children: any,
    width: number
}) => {
    
    return (
        <div style={{
            position: 'fixed',
            top: 0, right: 0, bottom: 0, left: 0,
            background: 'rgba(0, 0, 0, 0.45)',
            zIndex: 10,

            display: 'flex',
            alignContent: 'center',
            alignItems: 'center'
        }}>

            <div style={{
                position: 'absolute',
                borderRadius: 24,
                background: 'white',
                top: 90,
                bottom: 90,
                width: width,
                left: `calc((100% - ${width}px) / 2)`,
            }}>

                {children}

            </div>

        </div>
    )
}