import ChatComponent, { chatFullWidth, chatSideGap, chatWidth } from "./ChatComponent"
import ExhumanComponent, { defaultExhumanWidth, exhumanInVoiceModeWidthSmall } from "./ExhumanComponent"
import './css/ChatWithExhumanComponent.css'
import ProfileWidget from "./ProfileWidget"
import HeaderComponent from "./HeaderComponent"
import { useSelector } from "react-redux"
import { selectCurrentAvatarConfig, selectIsChatShownInVoiceMode, selectIsVoiceMode } from "../../app/redux/defaultSlice"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const gap = 25

export default () => {
    const currentAvatar = useSelector(selectCurrentAvatarConfig)
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentAvatar) {
            navigate('/')
        }
    }, [currentAvatar])

    const isVoiceMode = useSelector(selectIsVoiceMode)
    const isChatShownInVoiceMode = useSelector(selectIsChatShownInVoiceMode)

    let exhWidth
    let isMinimalMargin = false
    let contentWidth = undefined //(isVoiceMode ? exhumanInVoiceModeWidthSmall : defaultExhumanWidth) + gap + (isVoiceMode ? 0 : chatFullWidth)
    if (isVoiceMode) {
        if (isChatShownInVoiceMode) {
            exhWidth = exhumanInVoiceModeWidthSmall
            contentWidth = exhumanInVoiceModeWidthSmall + gap + chatFullWidth

        } else {
            exhWidth = '100%'
            isMinimalMargin = true
        }

    } else {
        contentWidth = defaultExhumanWidth + gap + chatFullWidth
        exhWidth = defaultExhumanWidth
    }

    return <div className='h-full'>
        <HeaderComponent />
        <div
            className='main-block'
            style={{
                width: contentWidth,
                minWidth: 500,
                marginLeft: isMinimalMargin ? 25 : `max(50% - ${contentWidth! / 2}px, 25px)`,
            }}>
            <div
                style={{
                    height: '100%',
                    // padding: '24px 0px 32px 0px',
                    // background: 'orange',
                    paddingTop: 25,
                    marginRight: gap,
                    width: exhWidth
                    // width: '100%'
                }}>
                <ExhumanComponent />
            </div>
            <div
                style={{
                    // width: '100%',
                    width: chatFullWidth,
                    height: '100%',
                    marginBottom: 25,
                    display: (isVoiceMode && !isChatShownInVoiceMode) ? 'none': undefined
                    // padding: '0px 32px 32px 24px',
                    // background: 'blue'
                }}>
                <ChatComponent />
            </div>
        </div>
    </div>
}