import { useDispatch } from "react-redux"
import { setIsPTTActive, setLastMessageToSend } from "../../app/redux/defaultSlice"
import { useEffect, useRef, useState } from "react"
import { setupPTTSpeechRecognition, startSpeechRecognition, stopSpeechRecognition } from "../../app/voice-api-ptt"
import { customAlert } from "../../app/utils"

export default () => {
    const dispatch = useDispatch()
    const [isSpacePressed, setIsSpacePressed] = useState(false)
    const [isRecognitionOff, setIsRecognitionOff] = useState(false)

    const speechStartedRef = useRef(false)
    useEffect(() => {
        let timeoutId: any
        if (isSpacePressed) {
            timeoutId = setTimeout(() => {
                try {
                    startSpeechRecognition()
                    speechStartedRef.current = true
                    dispatch(setIsPTTActive(true))
                } catch (err) {
                    customAlert('Too many clicks to start voice recognition. Please, reload the page.')
                }
            }, 300)

        } else if (speechStartedRef.current) {
            stopSpeechRecognition()
            speechStartedRef.current = false
            dispatch(setIsPTTActive(false))
        }
        return () => clearTimeout(timeoutId)
    }, [isSpacePressed])

    useEffect(() => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })
                .then((mediaStream) => {
                    let tracks = mediaStream.getTracks();
                    tracks.forEach(track => track.stop());
                })
                .catch((err) => {
                    console.error('getUserMedia error', err)
                    setIsRecognitionOff(true)
                })
        } else {
            console.error('getUserMedia not supported in this browser.');
        }

        const onNotAvailable = () => {
            setIsRecognitionOff(true)
        }
        const onRecognized = (text: string, latency: number | null) => {
            dispatch(setLastMessageToSend({
                text,
                inputType: 'web asr',
                latency
            }))
        }
        const onError = (err: string) => {
            customAlert(`Voice recognition error: ${err}`)
            dispatch(setIsPTTActive(false))
        }
        const onSpeaking = (isSpeaking: boolean) => { }
        setupPTTSpeechRecognition(onNotAvailable, onRecognized, onError, onSpeaking)

        const keyDownHandler = function (event: any) {
            if (event.code == 'Space') {
                setIsSpacePressed(true)
            }
        }

        const keyUpHandler = function (event: any) {
            if (event.code == 'Space') {
                setIsSpacePressed(false)
            }
        }

        window.addEventListener('keydown', keyDownHandler);
        window.addEventListener('keyup', keyUpHandler);

        return () => {
            window.removeEventListener('keydown', keyDownHandler);
            window.removeEventListener('keyup', keyUpHandler);
        }
    }, []);

    return <></>
}