import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { AvatarAnswer, AvatarConfig, Message, SuggestedTopics, UserQuestion } from '../types';

export interface DefaultState {
    lastMessageToSend: UserQuestion | null;
    avatarAnswer: AvatarAnswer | null;
    currentAvatarConfig: AvatarConfig | null;
    messages: Message[] | null
    avatars: AvatarConfig[] | null;
    currentSuggestedTopics: SuggestedTopics | null
    askedQuestions: string[]
    isExhumanMuted: boolean,
    isPTTActive: boolean,
    isVoiceMode: boolean,
    isChatShownInVoiceMode: boolean
}

const initialState: DefaultState = {
    lastMessageToSend: null,
    avatarAnswer: null,
    currentAvatarConfig: null,
    messages: null,
    avatars: null,
    currentSuggestedTopics: null,
    askedQuestions: [],
    isExhumanMuted: false,
    isPTTActive: false,
    isVoiceMode: false,
    isChatShownInVoiceMode: false
};

export const defaultSlice = createSlice({
    name: 'default',
    initialState,
    reducers: {
        setLastMessageToSend: (state, action: PayloadAction<UserQuestion | null>) => {
            state.lastMessageToSend = action.payload;
        },
        setAvatarAnswer: (state, action: PayloadAction<AvatarAnswer | null>) => {
            state.avatarAnswer = action.payload;
        },
        setCurrentAvatarConfig: (state, action: PayloadAction<AvatarConfig>) => {
            state.currentAvatarConfig = action.payload;
        },
        setMessages: (state, action: PayloadAction<Message[]>) => {
            state.messages = action.payload;
        },
        setAvatars: (state, action: PayloadAction<AvatarConfig>) => {
            state.currentAvatarConfig = action.payload;
        },
        setСurrentSuggestedTopics: (state, action: PayloadAction<SuggestedTopics>) => {
            state.currentSuggestedTopics = action.payload;
        },
        setAskedQuestions: (state, action: PayloadAction<string[]>) => {
            state.askedQuestions = action.payload;
        },
        setIsExhumanMuted: (state, action: PayloadAction<boolean>) => {
            state.isExhumanMuted = action.payload;
        },
        setIsPTTActive: (state, action: PayloadAction<boolean>) => {
            state.isPTTActive = action.payload;
        },
        setIsVoiceMode: (state, action: PayloadAction<boolean>) => {
            state.isVoiceMode = action.payload;
        },
        setIsChatShownInVoiceMode: (state, action: PayloadAction<boolean>) => {
            state.isChatShownInVoiceMode = action.payload;
        },
    },
});

export const {
    setLastMessageToSend,
    setAvatarAnswer,
    setCurrentAvatarConfig,
    setMessages,
    setСurrentSuggestedTopics,
    setAskedQuestions,
    setIsExhumanMuted,
    setIsPTTActive,
    setIsVoiceMode,
    setIsChatShownInVoiceMode
} = defaultSlice.actions;

export const selectLastMessageToSend = (state: RootState) => state.default.lastMessageToSend;
export const selectAvatarAnswer = (state: RootState) => state.default.avatarAnswer;
export const selectCurrentAvatarConfig = (state: RootState) => state.default.currentAvatarConfig;
export const selectMessages = (state: RootState) => state.default.messages;
export const selectIsOnlyFirstIntroMessage = (state: RootState) => (state.default.messages ?? []).length == 1;
export const selectCurrentSuggestedTopics = (state: RootState) => state.default.currentSuggestedTopics;
export const selectAskedQuestions = (state: RootState) => state.default.askedQuestions;
export const selectIsExhumanMuted = (state: RootState) => state.default.isExhumanMuted;
export const selectIsPTTActive = (state: RootState) => state.default.isPTTActive;
export const selectIsVoiceMode = (state: RootState) => state.default.isVoiceMode;
export const selectIsChatShownInVoiceMode = (state: RootState) => state.default.isChatShownInVoiceMode;

export default defaultSlice.reducer;
