import { useDispatch, useSelector } from 'react-redux';
import { TextareaAutosize } from './TextareaAutosize';
import { useEffect, useRef } from 'react';
import PTTComponent from './PTTComponent';
import { selectIsPTTActive, setIsPTTActive } from '../../app/redux/defaultSlice';

const InputComponent = ({ placeholder, value, onChange, onEnter, onHeightChange, onSuggestedTopics }: {
    placeholder: string
    value: string,
    onChange: (newValue: string) => void,
    onEnter: () => void,
    onHeightChange: (h: number) => void,
    onSuggestedTopics: () => void
}) => {
    const dispatch = useDispatch()
    const textAreaRef = useRef(null)
    const isPTTActive = useSelector(selectIsPTTActive)

    useEffect(() => {
        const spaceFixedValue = value.trim()
        onChange(spaceFixedValue)
    }, [isPTTActive])

    return (
        <div className='w-full'>
            <div
                className='input-container relative'>
                <TextareaAutosize
                    className='h-min-[52px] w-full pl-[50px] pr-[88px] pt-[13px] pb-[14px] focus:outline-none'
                    ref={textAreaRef}
                    autoFocus
                    spellCheck={isPTTActive ? false : true }
                    placeholder={isPTTActive ? '' : placeholder}
                    onKeyDownCapture={(event: any) => {
                        if (event.key === 'Enter') {
                            onEnter()
                            event.preventDefault()
                        }
                    }}
                    style={{
                        borderRadius: 16,
                        borderWidth: 1,
                        borderColor: '#d1d5dc',
                        color: isPTTActive ? 'transparent' : undefined
                    }}
                    value={value}
                    onChange={(event: any) => {
                        // don't allow multiple spaces in a row for the sake of PTT
                        let newValue: string = event.target.value
                        if (newValue.endsWith('  ')) {
                            newValue = newValue.substring(0, newValue.length - 1)
                        }
                        onChange(newValue)
                    }}
                    onResize={(event: any) => {
                        onHeightChange((event.target as any).offsetHeight)
                    }}
                />

                <img
                    style={{
                        position: 'absolute',
                        bottom: 20 + (isPTTActive ? -4 : 0),
                        left: 15 + (isPTTActive ? -4 : 0),
                        cursor: 'pointer'
                    }}
                    onMouseDown={() => dispatch(setIsPTTActive(true))}
                    onMouseUp={() => dispatch(setIsPTTActive(false))}
                    src={`/images/input-mic${isPTTActive ? '-active' : ''}.svg`} />

                {isPTTActive && <img style={{
                        width: 60,
                        height: 50,
                        position: 'absolute',
                        bottom: '50%',
                        left: '50%',
                        transform: 'translate(-50%, 43%)'
                    }} src='/images/ptt-waves.gif' />}

                <button
                    onClick={() => {
                        onEnter();
                        (textAreaRef.current as any).focus()
                    }}
                    style={{
                        position: 'absolute',
                        right: 14,
                        bottom: 17,
                        width: 32,
                        height: 32,
                        borderRadius: 8,
                        display: isPTTActive ? 'none' : undefined
                    }}>
                    <div>
                        {arrowUp(value.length ? 'white' : 'rgba(174, 180, 191, 1)',
                            value.length > 0 ? '#0094FF' : 'rgba(231, 233, 237, 1)')}
                    </div>
                </button>

                <button
                    className='suggested-topics'
                    onClick={() => {
                        onSuggestedTopics()
                    }}
                    style={{
                        position: 'absolute',
                        right: 50,
                        bottom: 17,
                        width: 32,
                        height: 32,
                        display: isPTTActive ? 'none' : undefined
                    }}>
                    <div>
                        <img src='/images/st-bubble.svg' />
                    </div>
                </button>

            </div>

            <div className='input__hold-space-title text-center mt-[2px] mb-[6px]'>
                {isPTTActive ? 'Release to send' : 'Hold space to talk'}
            </div>

            <PTTComponent />
        </div>
    )
}

export const defaultInputHeight = 52    // check if check padding and so on

const arrowUp = (color: string, fill: string) => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill={fill} />
    <path d="M16 23V9" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9 16L16 9L23 16" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
</svg>


export default InputComponent